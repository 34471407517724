/* AppBar */
#app-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
    margin-bottom: 20px;
}

#burger-button,
#round-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 30px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #5d5d5d;
    line-height: 0;
}

#burger-button:hover,
#round-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

#app-bar-title {
    font-size: 18px;
    font-weight: bold;
    color: #5d5d5d;
}

/* Side Menu */
#side-menu {
    position: fixed;
    top: 0;
    left: -80%; /* Initially hidden */
    width: 80%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition: left 0.3s ease; /* Smooth slide-in/out */
}

#side-menu-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

#side-menu.open {
    left: 0; /* Slide in when open */
}

#side-menu ul {
    list-style: none;
    padding: 10px 0;
    margin: 0 0px 20px 0px;
    border-bottom: 1px solid #ccc;
}

#side-menu ul li {
    padding: 6px 10px;
    border-radius: 10px;
    cursor: pointer;
}

#side-menu ul li:hover {
    background: rgba(0, 0, 0, 0.05);
}

#side-menu .close-button {
    align-self: flex-end;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 20px;
}

.day-separator-header {
    margin: 0 0 0 10px;
    font-weight: 500;
    opacity: 0.5;
}
