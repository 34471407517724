@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  background: white;
}

code {
  white-space: pre; /* Preserves formatting */
  display: block;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
