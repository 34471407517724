#chat-window {
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    transition: padding-bottom 0.3s ease;
    height: calc(100vh - 60px);
    position: relative;
    padding-bottom: 60px;
}

#chat-header {
    background: #eee;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.chat-header-picture {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#chat-header-name {
    font-size: 16px;
    margin-bottom: 4px;
}

#chat-header-description {
    display: flex;
    align-items: center;
    gap: 4px;
}

#description-text {
    font-size: 12px;
    opacity: 0.7;
}

.chat-support-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.close-icon {
    font-size: 30px!important;
    width: 30px;
    opacity: 0.8;
}

#messages { 
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-width: 100%;
    padding-top: 100px;
}

#messages .assistantDiv p:first-of-type {
    margin-block-start: 0;
}

#messages::-webkit-scrollbar {
    display: none;
}

#controls {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 10px 20px;
    background: white;
    transition: transform 0.3s ease;
    z-index: 10;
}

/*
#controls:focus-within {
    position: relative;
    padding-bottom: 50px;
  }*/

#input-container {
    background: #eee;
    border-radius: 50px;
    padding: 5px 14px 5px 14px;
    display: flex;
    align-items: center;
    width: 100%;
}

#user-input { 
    padding: 0 10px 0 0;
    line-height: 1.6!important;
    overflow-y: hidden;
    width: 100%;
    border: none;
    background: none;
    font-size: 14px;
    resize: none;
    line-height: 1;
    min-height: 40px;
    max-height: 100px;
    align-content: center;
    font-family: Arial, sans-serif;
    vertical-align: middle;
}

textarea:focus {
    border: none!important;
    outline: none !important;
}

#chat-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 70px;
    height: 70px;
    padding: 20px 20px;
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-chat {
    text-align: center;
    width: 40px;
    font-size: 40px!important;
}

#send-button {
    padding: 4px;
    margin: 0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    background-color: #333;
}

#send-button img {
    width: 30px;
}

.send-icon {
    font-size: 36px!important;
}

.material-icons {
    vertical-align: middle;
    display: flex;
}

.userWrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    margin-right: 30px;
}

.assistantWrapper {
    margin-bottom: 20px;
    display: flex;
    margin-right: auto;
    align-items: flex-start;
    max-width: 100%;
}

.messageWrapper {
    border-radius: 14px 14px 14px 14px;
    display: inline-block;
    margin-left: 10px;

    max-width: calc(100% - 30px); /* Leave space for margins and bot picture */
    word-wrap: break-word; /* Break long text */
    overflow-wrap: break-word; /* Ensure compatibility with older browsers */
    box-sizing: border-box;
}

.hint {
    font-size: 12px;
    margin-bottom: 5px;
    opacity: 0.5;
}

.assistantWrapper .messageWrapper {
    padding: 0
}

.userWrapper .messageWrapper {
    background: #eee;
    padding: 10px;
}

.assistantDiv, .userDiv {
    font-size: 14px;
}

.assistantDiv.loading::after {
    content: '...';
    animation: dots 1s steps(3, end) infinite;
}

@keyframes dots {
    0%, 20% {
        color: transparent;
    }
    100% {
        color: black;
    }
}

.button-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 50px;
}

.under-button {
    background: none;
    padding: 4px 8px;
    border-radius: 10px;
    margin: 0;
    cursor: pointer;
    color: #777;
    font-size: 12px;
    display: inline-block;
    width: fit-content;
    border: 1px solid #999;
    font-family: inherit;
    margin-bottom: 10px;
}

.form-header {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;
}

.assistantForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.assistantForm label {
    display: none;
  }

.assistantForm input::placeholder {
  color: #777777;
}

.assistantForm button[type="submit"] {
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: #0078d4;
  color: #000;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  padding: 10px;
  color: white;
}

.assistantForm button[type="submit"]:hover {
  background-color: #0063af;
}

.assistantForm button[type="submit"]:active {
    background-color: #004980;
  }

.input-div {
  width: 100%;
  padding: 0;
}

.input-div input {
  width: 100%;
  box-sizing: border-box;
  margin: 0px 0px 10px 0px;
  padding: 10px;
  border: 1px solid #0078d4;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  font-family: inherit;
}

.input-div input:focus {
    border: 1px solid #0063af!important;
}

#powered-by {
    font-size: 10px;
    opacity: 0.5;
    text-align: center;
    margin: 0px 0 10px 0;
}

.thread-name {
    white-space: nowrap;
    overflow: hidden;
    display: block; 
    text-overflow: clip;
    width: 100%;
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
    -webkit-mask-clip: text;
    -webkit-mask-repeat: no-repeat;
    color: #222;
    font-weight: 500;
    
    mask-image: linear-gradient(to right, black 80%, transparent 100%);
    mask-clip: text;
    mask-repeat: no-repeat;; 
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1); /* Black with 50% opacity */
    z-index: 1000; /* Ensure it appears behind the side menu */
}

code {
    padding: 2px 6px;
    display: inline-block;
}

pre code {
    background: #222;
}

pre, code {
    max-width: 100%; /* Restrict to parent container */
    white-space: pre-wrap; /* Allow wrapping within the parent */
    word-wrap: break-word; /* Break long lines if necessary */
    border-radius: 10px; /* Rounded corners for better appearance */
    margin: 0;
    border: 0;
  }

pre {
    overflow-x: auto; /* Enable horizontal scrolling for long lines */
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin: 0;
}

pre::-webkit-scrollbar {
    display: none;
}
  
  pre div {
    margin: 0!important;
    border-radius: 10px;
    padding: 0!important;
    background: #222!important;
  }

blockquote {
    background: #eee;
    padding: 10px 20px;
    margin: 0;
    border-radius: 10px;
}

blockquote p {
    font-style: italic;
}