.loading-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 10px 0 10px 0;
}

.loading-dots span {
    width: 8px; /* Size of each dot */
    height: 8px; /* Size of each dot */
    background-color: #333; /* Dot color */
    border-radius: 50%; /* Make it circular */
    animation: blink 1.4s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}
